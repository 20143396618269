import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

import { Section, Container, Row, Column, Heading } from '../components/ui';
import { WaveDivider, Button, FullwidthCTA, Layout, SEO } from '../components';

import {
    DisputeSection,
    ServicesSection,
} from '../components/section_components';

import { breakpoints } from '../utils/screenSize';

const Page = (props: RouteComponentProps) => {
    const data = useStaticQuery(graphql`
        query Images_v2_2_refactor {
            phonesLg: file(relativePath: { eq: "phone-preview-screen.png" }) {
                publicURL
            }
            phonesSm: file(
                relativePath: { eq: "phone-preview-screen-sm.png" }
            ) {
                publicURL
            }
        }
    `);

    return (
        <Layout>
            <SEO
                title="Get the Tools to Improve Your Credit | ScoreShuttle"
                description="Use ScoreShuttle to dispute report errors and get the know-how needed to improve your credit scores, digitally."
            />
            <Section>
                <Container className="pb-0 lg:pb-16">
                    <Row className="flex flex-wrap lg:flex-no-wrap">
                        <Column className="text-center lg:text-left w-auto relative z-10 lg:mr-2 w-full lg:w-3/5">
                            <div className="container-narrow">
                                <Heading
                                    headingLevel={1}
                                    text="Do more than check your credit score. LAUNCH it!"
                                    className="lg:text-left lg:mx-0"
                                />
                                <p className="font-heading text-lg sm:text-xl md:text-2xl mb-10 lg:text-left">
                                    ScoreShuttle has all the tools you need to
                                    launch your credit scores with all 3
                                    bureaus.
                                </p>
                                <ul className="font-light text-left leading-relaxed list-disc pl-5 mb-10 sm:text-md lg:text-lg">
                                    {[
                                        "Digitally Dispute Errors in ScoreShuttle's Automated Dispute Center",
                                        '3-Bureau Reports, Scores, and Credit Monitoring',
                                        'Unlock Expert-Tailored Score Secrets in Credit Camp',
                                        'Supercharged Security and ID Theft Protection',
                                    ].map((listItem, index) => (
                                        <li key={index}>{listItem}</li>
                                    ))}
                                </ul>
                                <Button
                                    theme="blue"
                                    onClick={() =>
                                        window.open(
                                            'https://app.scoreshuttle.com/setup'
                                        )
                                    }
                                >
                                    START FOR FREE
                                </Button>
                            </div>
                        </Column>
                        <Column className="relative z-10 w-full lg:w-2/5 flex xl:block">
                            <picture className="mx-auto lg:flex items-center justify-center xl:block">
                                <source
                                    media={`(min-width: ${breakpoints.lg}px)`}
                                    srcSet={data.phonesLg.publicURL}
                                />
                                <img
                                    className="mx-auto mt-8 lg:mt-0 xl:-mt-8"
                                    src={data.phonesSm.publicURL}
                                    alt="cell phones displaying ScoreShuttle web pages"
                                />
                            </picture>
                        </Column>
                    </Row>
                </Container>
                <WaveDivider
                    foregroundColor="bg-ss-blue-100"
                    backgroundColor="text-white"
                    flip="y"
                    inset="bottom"
                />
            </Section>
            <DisputeSection
                className="bg-ss-blue-100"
                bullets={[
                    `ScoreShuttle gives you the power to dispute report errors in one click.`,
                    `AI smarts help you identify potential problem areas.`,
                    `Spot an error? Click to launch ScoreShuttle's Automated Dispute Cycle.`,
                    `Anytime an error is removed, you could see an uptick in your scores.`,
                ]}
            />
            <WaveDivider
                foregroundColor="bg-ss-blue-100"
                backgroundColor="text-white"
                flip="x"
            />
            <ServicesSection />
            <FullwidthCTA headline="Ready to launch your credit scores?" />
        </Layout>
    );
};

export default Page;
